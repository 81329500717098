import React, {useEffect, lazy, Suspense} from 'react'
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
import 'typeface-roboto'
import './index.css'
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles'
import reportWebVitals from './reportWebVitals';
import {Routes} from './components/router/'
import Auth from './components/Auth/Auth'
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const auth = new Auth()
const theme = createTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      '-apple-system',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#0099B0',
      main: '#007C8E',
      dark: '#00575E'
    },
    secondary: {
      light: '#E6007C',
      main: '#E6007C',
      dark: '#E6007C'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    }
  }
})
const container = document.getElementById('root');

function AppWithCallbackAfterRender() {
  return <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <Routes auth={auth}/>
    </MuiThemeProvider>
  </BrowserRouter>
}

const root = createRoot(container);
root.render(<DevSupport ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
>
  <AppWithCallbackAfterRender/>
</DevSupport>);
reportWebVitals();
