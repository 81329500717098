import React, {lazy, Suspense, useEffect} from 'react'
import {Switch, useLocation} from 'react-router-dom'
import {MakeRouteWithSubRoutes} from './makeRouteWithSubRoutes'
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
const Dashboard = lazy(() => import('../pages/Dashboard'));
const PageNotFoundComponent = lazy(() => import('../pages/PageNotFound'));
const ServicesSearch = lazy(() => import('../pages/ServicesSearch'));
const ServicesSearchTabs = lazy(() => import('../pages/ServicesSearch/servicesSearchTabs'));
const Services = lazy(() => import('../pages/Services'));
const Contact = lazy(() => import('../pages/Contact'));
const ContactConfirm = lazy(() => import('../pages/Contact/contactConfirm'));
const Resources = lazy(() => import('../pages/Resources'));
const Events = lazy(() => import('../pages/Events'));
const ResourcesSingle = lazy(() => import('../pages/Resources/ResourcesSingle'));
const SingleServices = lazy(() => import('../pages/Services/SingleServices'));
const FaqPage = lazy(() => import('../pages/FAQ'));
const QuotesPage = lazy(() => import('../pages/Quotes'));
const CareerCoaching = lazy(() => import('../pages/StaticPage/CareerCoaching'));

const routesNotLoged = [
  {
    path: '/',
    exact: true,
    component: Dashboard
  },
  {
    path: '/contact-support',
    component: Contact
  },
  {
    path: '/contact-confirm',
    component: ContactConfirm
  },
  {
    path: '/services-search',
    component: ServicesSearch,
  },
  {
    path: '/services-search-tabs',
    component: ServicesSearchTabs,
  },
  {
    path: '/disability-employment-services/organisation/:slug',
    component: SingleServices,
  },
  {
    path: '/disability-employment-services/:params',
    component: Services,
  },
  {
    path: '/disability-employment-services',
    component: Services,
  },
  {
    path: '/disability-career-resources/search-result/:slug',
    component: ResourcesSingle,
  },
  {
    path: '/disability-career-resources/:params/:slug',
    component: ResourcesSingle,
  },
  {
    path: '/disability-career-resources/:params',
    component: Resources,
  },
  {
    path: '/disability-career-resources',
    component: Resources,
  },
  {
    path: '/events',
    component: Events,
  },
  {
    path: '/faq',
    component: FaqPage,
  },
  {
    path: '/career-coaching-for-disabled-candidates',
    component: CareerCoaching,
  },
  {
    path: '/quotes',
    component: QuotesPage,
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

export const Routes = (props) => {
  const authenticated = props.auth.isAuthenticated();
  let routes = routesNotLoged;
  if (authenticated === 'true') {
    //routes = routesLoged
    routes = routesNotLoged
  }

  ReactGA.initialize('G-KWZPRQZ1N1', {debug: false});
  const location = useLocation();
  const tagManagerArgs = {
    gtmId: 'G-KWZPRQZ1N1'
  }

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    TagManager.initialize(tagManagerArgs);
  }, [location]);

  return (
    <Suspense fallback={<div/>}>
      <Switch>
        {
          routes.map(
            (route, index) => <MakeRouteWithSubRoutes key={index} {...route} {...props} />
          )
        }
      </Switch>
    </Suspense>
  )
};
