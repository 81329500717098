import md5 from 'md5'

export default class Auth {
  constructor() {
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getUser = this.getUser.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
  }

  isAuthenticated() {
    return String(localStorage.getItem('isToken') && localStorage.getItem('token') === '4f00d3dbf6bfa823873a46afbbfdf99a')
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  login(res) {
    if (md5(res) !== '4f00d3dbf6bfa823873a46afbbfdf99a') {
      return this.logout();
    }
    localStorage.setItem('isToken', !!(res))
    localStorage.setItem('token', md5(res))
    window.location.reload()
  }

  logout() {
    localStorage.clear()
    window.location.reload()
  }
}
